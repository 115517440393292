<template>
  <div id="blob"></div>
  <div id="blur"></div>
  <nav>
    <div id="nav-left">
      <a href="https://www.linkedin.com/in/jakobschlosser/" target="_blank" rel="noopener noreferrer"><img class="nav-icon" src="@/assets/linkedin.png" alt="linkedin"/></a>
      <a href="mailto:jakob.schlosser97@gmail.com"><img class="nav-icon" src="@/assets/mail.png"/></a>
    </div>
    <div id="nav-right">
      <router-link to="/" exact-active-class="active-link">about me</router-link>
      <router-link to="/projects" exact-active-class="active-link">projects</router-link>
    </div>
  </nav>
  <div class="content">
    <router-view/>
  </div>
  <div class="footer">
    <router-link to="/impressum">Imprint</router-link>
    <router-link to="/attributions">Attributions</router-link>
  </div>
</template>