import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Impressum from "../views/Impressum.vue"
import Attributions from '@/views/Atrributions.vue'
import Projects from '@/views/Projects.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    }
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Impressum
  },
  {
    path: "/attributions",
    name: "attributions",
    component: Attributions
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
