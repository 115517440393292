<template>
    <div id="projects">
        <div>
            <a href="https://www.carbon-cleanup.com" target="_blank" rel="noopener noreferrer" style="text-decoration: none;"><h3>@ Carbon Cleanup GmbH</h3></a>
            <p>development of mobile micro-factories for recycling of composite materials</p>
            <ul>
                <li>leading development of mobile micro-factories for recycling of composite materials</li>
                <li>company development</li>
                <li>supporting in research projects</li>
                <li>web development of online tools with flask</li>
            </ul>
            <div style="display: flex; align-items: center; margin: 0;">
                <p>about our work: </p>
                <a href="https://www.trendingtopics.eu/carbon-cleanup-interview/" target="_blank" rel="noopener noreferrer" style="margin-right: 10px; text-decoration: underline;"><img src="@/assets/link-128.png" style="height: 8px; width: auto; margin: 0 4px 0 4px;" alt="link"/>trendingtopics.eu</a>
                <a href="https://www.ffg.at/sites/default/files/allgemeine_downloads/basisprogramme/forschungserfolge/CarbonCleanupGmbH.pdf" target="_blank" rel="noopener noreferrer" style="margin-right: 10px; text-decoration: underline;"><img src="@/assets/link-128.png" style="height: 8px; width: auto; margin: 0 4px 0 4px;" alt="link"/>ffg.at</a>
            </div>
        </div>
        <div>
            <a href="" target="_blank" rel="noopener noreferrer" style="text-decoration: none;"><h3>@ Universty of Applied Sciences Upper Austria</h3></a>
            <p>development of a carbon fiber UAV with 2 kg payload</p>
            <div style="display: flex; align-items: center; margin: 0;">
                <p>about the project: </p>
                <a href="https://www.compositesworld.com/news/hexcel-sponsorship-showcases-carbon-fiber-prepreg-capabilities-for-uav-applications" target="_blank" rel="noopener noreferrer" style="margin-right: 10px; text-decoration: underline;"><img src="@/assets/link-128.png" style="height: 8px; width: auto; margin: 0 4px 0 4px;" alt="link"/>compositesworld.com</a>
                <a href="https://press.facc.com/News_Detail.aspx?id=133950&menueid=14308&l=english" target="_blank" rel="noopener noreferrer" style="margin-right: 10px; text-decoration: underline;"><img src="@/assets/link-128.png" style="height: 8px; width: auto; margin: 0 4px 0 4px;" alt="link"/>facc.com</a>
            </div>
        </div>
    </div>
</template>

